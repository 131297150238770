import { version } from '../../package.json';

export const baseUrl: string = 'https://apps.harperjames.co.uk';
//export const baseUrl: string = 'https://uat.harperjames.co.uk';
// export const baseUrl: string = 'https://localhost:44360';

export const environment = {
  production: false,
  apiUrl: `${baseUrl}/api`,
  appVersion: version + '-dev (3589)',
  appInsightsConnectionString:
    'InstrumentationKey=3cb9d539-4583-4ec9-af74-2160d772fb8e;IngestionEndpoint=https://uksouth-1.in.applicationinsights.azure.com/;LiveEndpoint=https://uksouth.livediagnostics.monitor.azure.com/',

  config: {
    auth: {
      clientId: 'b7875d1b-9e0a-4ad9-8bb0-1fc0352809aa',
      //clientId: 'e7765baf-00d5-4e0c-aeba-8f74f03937ce',
      authority: 'https://login.microsoftonline.com/ff9c468c-4463-4262-a31d-5401f37e4b9d',
      validateAuthority: true,
      redirectUri: '/',
      postLogoutRedirectUri: '/logout',
      navigateToLoginRequestUrl: true,
    },
    cache: {
      cacheLocation: 'localStorage',
    },
    resources: {
      demoApi: {
        resourceUri: 'https://graph.microsoft.com/.default',
        resourceScope: 'api://b7875d1b-9e0a-4ad9-8bb0-1fc0352809aa/ReadUser', // here use the client id of the Web API you registered
        //resourceScope: 'api://e7765baf-00d5-4e0c-aeba-8f74f03937ce/ReadUser', // here use the client id of the Web API you registered
      },
    },
    scopes: {
      loginRequest: ['user.read email'],
    },
  },
};
